import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./services/router";
import store from "./store/index";
import { i18n } from "@/plugins/i18n/index";
import CountryFlag from "vue-country-flag";

Vue.component("country-flag", CountryFlag);
Vue.config.productionTip = false;
Vue.config.productionTip = false;
new Vue({
  vuetify,
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
