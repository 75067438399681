
import Footer from "../components/Footer.vue";
import { Component, Vue } from "vue-property-decorator";
import Header from "../components/Header.vue";
import Card from "../components/Cards.vue";
import HowWorks from "../components/HowWorks.vue";
import CommonQuestions from "../components/CommonQuestions.vue";
@Component({
  components: {
    Header,
    Card,
    HowWorks,
    Footer,
    CommonQuestions,
  },
})
export default class Home extends Vue {}
