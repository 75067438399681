
import HeaderDocs from "../../components/HeaderDocs.vue";
import { Vue, Component } from "vue-property-decorator";
import Footer from "../../components/Footer.vue"
import { translate } from "../../plugins/i18n/index";

@Component({
  components: { HeaderDocs, Footer },
})
export default class TalkPresentation extends Vue {
  result = "https://www.youtube.com/watch?v=HuPK62LHCms";
  get questions() {
    return [
      {
        id: 1,
        title: `${translate("infoOfTheCallsMade")}`,
        question: `${translate("infoOfTheCallsMadeDescription")}`,
      },
      {
        id: 2,
        title: `${translate("authenticationCredentials")}`,
        question: `${translate("authenticationTalkCredentialsDescription")}`,
      },
    ];
  }
}
