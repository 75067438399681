
import { Vue, Component } from "vue-property-decorator";
@Component
export default class Footer extends Vue {
  icons = [
    { id: 1, icon: "mdi-whatsapp", color: "green", name: "whatsapp" },
    {
      id: 2,
      icon: "mdi-facebook",
      color: "blue",
      name: "facebook",
    },
    { id: 3, icon: "mdi-linkedin", color: "blue", name: "linkedin" },
    { id: 4, icon: "mdi-instagram", color: "brown", name: "instagram" },
    { id: 5, icon: "mdi-twitter", color: "blue", name: "twitter" },
    { id: 6, icon: "mdi-youtube", color: "red", name: "youtube" },
    //{ id: 7, icon: "mdi-music-circle", color: "black", name: "tiktok" },
  ];
  padless = false;

  redirect(from: string) {
    if (from === "facebook") {
      window.open("https://www.facebook.com/ai.matrixgo", "_blank");
    }
    if (from === "youtube") {
      window.open("https://www.youtube.com/@MATRIX__GO", "_blank");
    }
    if (from === "instagram") {
      window.open("https://www.instagram.com/matrixgo.ai/", "_blank");
    }
    if (from === "linkedin") {
      window.open("https://www.linkedin.com/company/matrixgoai", "_blank");
    }
    if (from === "whatsapp") {
      window.open(
        "https://api.whatsapp.com/send?phone=5508006045555&submissionGuid=960fc0bb-f277-40df-8b6e-ca91ed07bdd3",
        "_blank"
      );
    }
    if (from === "twitter") {
      window.open("https://twitter.com/Matrix__go", "_blank");
    }
 //    if (from === "tiktok") {
 //      window.open("https://www.tiktok.com/@matrix__go", "_blank");
 //    }
  }
}
