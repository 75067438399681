
import { Vue, Component } from "vue-property-decorator";

import { translate } from "@/plugins/i18n/index";
@Component
export default class HowWorks extends Vue {
  hover = false;
  get cards() {
    return [
      {
        id: 1,
        color: "#802678",
        icon: "mdi-lock",
        description: `${translate("requestCredentials")}`,
        text: `${translate("requestCredentialsText")}`,
      },
      {
        id: 2,
        color: "#802678",
        icon: "mdi-book-variant",
        description: `${translate("developmentUsingDocumentation")}`,
        text: `${translate("developmentUsingDocumentationText")}`,
      },
      {
        id: 3,
        color: "#802678",
        icon: "mdi-format-list-checkbox",
        description: `${translate("applicationApproval")}`,
        text: `${translate("applicationApprovalText")}`,
      },

      {
        id: 4,
        color: "#802678",
        icon: "mdi-rocket",
        description: `${translate("production")}`,
        text: `${translate("productionText")}`,
      },
    ];
  }

  mounted() {
    if (localStorage.getItem("language") == "true") {
      return (this.$i18n.locale = "en-us");
    } else if (localStorage.getItem("language") == "false") {
      return (this.$i18n.locale = "pt-BR");
    }
  }
}
