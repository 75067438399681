import Vue from "vue";
import { Locales } from "./locales";
import en from "./en.json";
import pt from "./pt-BR.json";
import es from "./es.json";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'pt-BR',
  fallbackLocale: "en",
  messages: { pt, en, es },
  silentTranslationWarn: true,
});

const translate = (key: string) => {
  if (!key) {
    return "";
  }
  return i18n.t(key);
};

export const messages = {
  [Locales.EN]: en,
  [Locales.PT]: pt,
  [Locales.ES]: es,
};

export const defaultLocale = Locales.PT;
export { i18n, translate };
