
import { Component, Vue } from "vue-property-decorator";
import Home from "./views/Home.vue";

@Component({
  components: { Home },
})
export default class App extends Vue {
  mounted() {
    if (localStorage.getItem("language") == "true") {
      return (this.$i18n.locale = "en-us");
    } else if (localStorage.getItem("language") == "false") {
      return (this.$i18n.locale = "pt-BR");
    }
  }
}
