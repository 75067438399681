
import { Vue, Component } from "vue-property-decorator";
import { translate } from "@/plugins/i18n/index";
@Component
export default class ApiSideBarMobile extends Vue {
  drawer = false;
  group = null;
  navigation(route) {
    this.$router.push({ name: route });
  }
  get items() {
    return [
    /*
      {
        id: 3,
        name: "WHATSAPP",
        route: "WhatsappPresentation",
        icon: "mdi-whatsapp",
        child: [
          {
            id: 1,
            name: `${translate("presentation")}`,
            route: "WhatsappPresentation",
          },
          {
            id: 2,
            name: `${translate("documentation")}`,
            route: "WhatsappDocs",
          },
        ],
      },
      */
      {
        id: 1,
        name: `${translate("chatbot")}`,
        icon: "mdi-face-agent",
        route: "OminiPresentation",
        child: [
          {
            id: 1,
            name: `${translate("presentation")}`,
            route: "OminiPresentation",
          },
          {
            id: 2,
            name: `${translate("documentation")}`,
            route: "OminiDocs",
          },
        ],
      },

      {
        id: 2,
        name: `${translate("voice")}`,
        route: "TalkPresentation",
        icon: "mdi-phone",
        child: [
          {
            id: 1,
            name: `${translate("presentation")}`,
            route: "TalkPresentation",
          },
          {
            id: 2,
            name: `${translate("documentation")}`,
            route: "TalkDocs",
          },
        ],
      },

    ];
  }
}
