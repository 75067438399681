
import { Vue, Component } from "vue-property-decorator";
import { translate } from "@/plugins/i18n/index";
@Component
export default class CommonQuestions extends Vue {
  hover = false;
  get questions() {
    return [
      {
        id: 1,
        title: `${translate("developerPortal")}`,
        question: `${translate("developerPortalDescription")}`,
      },
      {
        id: 2,
        title: `${translate("whatsApi")}`,
        question: `${translate("whatsApiDescription")}`,
      },
      {
        id: 1,
        title: `${translate("whereCanIget")}`,
        question: `${translate("whatsApiDescription")}`,
      },
    ];
  }
}
