
import { Vue, Component } from "vue-property-decorator";
import Header from "../components/Header.vue";
@Component({
  components: { Header },
})
export default class Drawer extends Vue {
  drawer: any = false;
  redirect(from: string) {
    if (from === "facebook") {
      window.open("https://www.facebook.com/MatrixdoBrasil", "_blank");
    }
    if (from === "youtube") {
      window.open("https://www.youtube.com/c/MatrixDoBrasil", "_blank");
    }
    if (from === "instagram") {
      window.open("https://www.instagram.com/matrixbrasil/", "_blank");
    }
    if (from === "linkedin") {
      window.open("https://www.linkedin.com/company/matrix-brasil/", "_blank");
    }
  }
}
