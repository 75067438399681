
export enum Locales {
  PT = 'pt',
  EN = 'en',
  ES = 'es'
}

export const LOCALES = [
  { value: Locales.PT, caption: 'Português' },
  { value: Locales.EN, caption: 'English' },
  { value: Locales.ES, caption: 'Español' }
]