
import HeaderDocs from "../../components/HeaderDocs.vue";
import Footer from "../../components/Footer.vue";
import { Vue, Component } from "vue-property-decorator";
import { translate } from "../../plugins/i18n/index";
@Component({
  components: { HeaderDocs, Footer },
})
export default class OminiPresentation extends Vue {
  result = "https://www.youtube.com/watch?v=HuPK62LHCms";
  get questions() {
    return [
      {
        id: 1,
        title: `${translate("historyOfTheCallsMade")}`,
        question: `${translate("historyOfTheCallsMadeDescription")}`,
      },
      {
        id: 2,
        title: `${translate("authenticationCredentials")}`,
        question: `${translate("authenticationCredentialsDescription")}`,
      },
      {
        id: 3,
        title: `${translate("ominiNotification")}`,
        question: `${translate("ominiNotificationDescription")}`,
      },
      {
        id: 4,
        title: `${translate("CanIGetThisByAPI")}`,
        question: `${translate("CanIGetThisByAPIDescription")}`,
      },
    ];
  }
}
