
import { Component, Vue } from "vue-property-decorator";
import { mapMutations, mapState } from "vuex";

import CountryFlag from "vue-country-flag";
@Component({
  computed: {
    ...mapState(["Sidebar_drawer"]),
  },
  methods: {
    ...mapMutations({
      setSidebarDrawer: "SET_SIDEBAR_DRAWER",
    }),
  },
  components: {
    CountryFlag,
  },
})
export default class HeaderDocs extends Vue {
  Sidebar_drawer: any;
  value = false;
  language: any = true;
  // eslint-disable-next-line
  setSidebarDrawer: (sidebarDrawer: any) => any;
  changeLanguage(value) {
    switch (value) {
      case "en":
        this.$i18n.locale = "en-us";
        localStorage.setItem("language", "en");
        break;
      case "pt":
        this.$i18n.locale = "pt-BR";
        localStorage.setItem("language", "pt-BR");
        break;
      case "es":
        this.$i18n.locale = "es";
        localStorage.setItem("language", "es");
        break;

      default:
        this.$i18n.locale = "pt-BR";
        localStorage.setItem("language", "pt-BR");
        break;
    }
  }
  mounted() {
    const languageValue = localStorage.getItem("language");

    switch (languageValue) {
      case "en":
        this.$i18n.locale = "en-us";
        localStorage.setItem("language", "en");
        break;
      case "pt":
        this.$i18n.locale = "pt-BR";
        localStorage.setItem("language", "pt-BR");
        break;
      case "es":
        this.$i18n.locale = "es";
        localStorage.setItem("language", "es");
        break;

      default:
        this.$i18n.locale = "pt-BR";
        localStorage.setItem("language", "pt-BR");
        break;
    }
  }
}
