
import { Component, Vue } from "vue-property-decorator";
import HeaderText from "./HeaderText.vue";
import Drawer from "./Drawer.vue";
import CountryFlag from "vue-country-flag";

@Component({
  components: {
    HeaderText,
    Drawer,
    CountryFlag,
  },
})
export default class Home extends Vue {
  changeLanguage(value) {
    const languageMap = {
      en: "en-us",
      pt: "pt-BR",
      es: "es",
    };
    this.$i18n.locale = languageMap[value] || "pt-BR";
    localStorage.setItem("language", languageMap[value] || "pt-BR");
  }

  mounted() {
    const languageValue = localStorage.getItem("language");
    this.changeLanguage(languageValue || "pt");
  }
}
